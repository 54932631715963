import * as React from "react";

const Terminal = () => {
  const inputs = React.useRef(null);
  const input1 = React.useRef(null);
  React.useEffect(() => {
    input1.current.focus();
  }, []);
  const handleKeyPress = ({ key }) => {
    if (key === "Enter") {
      console.log("hit enter");
    }
  };
  return (
    <div className="w-4/5 mx-auto">
      <div className="w-full h-64 mx-auto subpixel-antialiased bg-black border-black rounded shadow-2xl">
        <div
          className="flex items-center h-6 text-center text-black bg-gray-100 border-b border-gray-500 rounded-t"
          id="headerTerminal"
        >
          <div
            className="flex items-center w-3 h-3 ml-2 text-center bg-red-500 border-red-900 rounded-full shadow-inner"
            id="closebtn"
          ></div>
          <div
            className="w-3 h-3 ml-2 bg-yellow-500 border-yellow-900 rounded-full shadow-inner"
            id="minbtn"
          ></div>
          <div
            className="w-3 h-3 ml-2 bg-green-500 border-green-900 rounded-full shadow-inner"
            id="maxbtn"
          ></div>
          <div className="pr-16 mx-auto" id="terminaltitle">
            <p className="text-sm text-center">Terminal</p>
          </div>
        </div>
        <div
          className="h-auto pt-1 pl-1 font-mono text-xs text-green-200 bg-black"
          id="console"
          ref={inputs}
        >
          <p className="pb-1">
            Last login:{" "}
            {new Date().toLocaleTimeString("en-GB", {
              weekday: "short",
              year: "numeric",
              month: "short",
              day: "numeric",
            })}{" "}
            on ttys002
          </p>
          <p className="pb-1">Devproject:Contact jonathan$</p>
          &gt;{" "}
          <input
            style={{ background: "transparent" }}
            onKeyPress={handleKeyPress}
            type="text"
            ref={input1}
          />
        </div>
      </div>
    </div>
  );
};

export default Terminal;
