import * as React from "react";
import Seo from "./../../components/Seo";
import Terminal from "./../../components/Terminal";
import "./styles.scss";

const ContactPage = () => (
  <>
    <Seo title="Contact" />
    <section id="contact-page" className="section is-medium">
      <Terminal />
    </section>
  </>
);

export default ContactPage;
